.field-label, .field-value{
   display: inline-block
}

.field-label{
   vertical-align: top;
   font-weight:500;
   width: 100px;
   line-height: 1.2;
}

.fieldtype-datetime-date {
    width: 125px;
    display: inline-block;
  }

.fieldtype-datetime-time {
    text-align: center;
    color: #736e6e;
    display: inline-block;
    width: 3rem;
}

.fieldtype-datetime > div{
    padding-top: 1%;
    width: 450px;
}

.fieldtype-relationlist.field-mode-edit .field-value{
  display: inline-block;
  padding: 0px 1rem;
}

.field-mode-edit.fieldtype-relationlist{
  width: 50%;
}

.field-mode-edit.fieldtype-relationlist .list-item{
  border: 1px dotted #999999;
  background: #fcfcfc;
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 5px;
}


.browse .selected .content-view{
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 3px;
  margin: 2px;
}

.blockview-grid{
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  border: 1px solid #999999;
  margin-bottom: 10px;
}


.blockview-cell{
  padding: 10px;
  background: #f3f3f3;
  margin: 2px;
  text-align: center;
}

.blockview-cell > *{
  width: 100%;
}

.blockview-cell > .fieldtype-image > img{
  max-width: 100%;
}

.browse-selected > td{
  font-weight: bold;
}

.content-viewmode-inline{
  display: inline-block;
  margin: 2px;
}

.blockview-cell.browse-selected{
  background: #f0f0f0
}

.field-relationlist-article > div{
  display: block;
}

.browse-list .content-view{
  cursor: pointer
}

.field-mode-edit.required .field-label::after{
  content: "*";
  color:red;
}

.field-validation-failed input{
   border-color: red;
   background-color: #f1e7e7;
}

.validation-failed-field-error{
  color: red
}

.field-password .field-value{
  display: block;
}

.fieldtype-relation-select{
  width: 200px;
}

.action-menu {
  font-size: 0.85em;
  cursor: pointer;
}

.content-baseattr-priority i{
  color: green;
  font-size: 0.8rem;
}

.browse .selected .close{
  font-size: 0.8rem;
  float: none;
  cursor: pointer;
}

.browse .selected .close::after{
  content: 'X'
}

.row-action-inline{
  display: inline-block;
  margin-right: 0.3rem;
}

.row-action-inline .action-item{
  display: inline-block;
}

.tox.tox-tinymce{
  z-index: 0;
}